.newsletter {
  background: #17bf9e;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
}
.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
